import BlackBackgroundColorForImages from '#app/components/black-background-color-for-images'
import PlentyButtonsAndLinks from '#app/components/typography/plenty-buttons-and-links'
import { Button } from '#app/components/ui/button'
import {
	type CategoryStoryblok,
	type ParentCategoryStoryblok,
} from '#types/component-types-sb'
import ScrollableContent from '../scrollable-content'
import { SlideContent, SlideTrigger } from '../slides'
import CategoriesContent from './categories-content'

interface SpecialCategoriesProps {
	category: ParentCategoryStoryblok
	parentCategory: string
}

const SpecialCategories = ({
	category,
	parentCategory,
}: SpecialCategoriesProps) => {
	return (
		<div className="special-wrap relative col-span-1 rounded-lg">
			<img
				src={category.mobileImage?.filename}
				alt={category.mobileImage?.alt}
				className="rounded-lg"
			/>
			<BlackBackgroundColorForImages className="rounded-lg" />
			<div className="z-10 flex h-full w-full flex-col items-center justify-between p-2">
				<div>
					<PlentyButtonsAndLinks as="nav-md" className="text-white">
						{typeof category.title === 'string' && category.title}
					</PlentyButtonsAndLinks>
				</div>
				<div className="flex w-full flex-col items-center justify-center gap-1">
					{category.categories?.map(
						(subCategories: ParentCategoryStoryblok | CategoryStoryblok) => {
							return (
								<div className="w-full" key={subCategories._uid}>
									<SlideTrigger
										slideKey={subCategories._uid}
										className="w-full"
									>
										<Button variant="secondary" size="secondary" fullWidth>
											{subCategories.title}
										</Button>
									</SlideTrigger>
									<SlideContent slideKey={subCategories._uid}>
										<ScrollableContent className="h-full space-y-3">
											<CategoriesContent
												category={subCategories}
												parentCategory={parentCategory || ''}
											/>
										</ScrollableContent>
									</SlideContent>
								</div>
							)
						},
					)}
				</div>
			</div>
		</div>
	)
}

export default SpecialCategories
