import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import PlentyButtonsAndLinks from '#app/components/typography/plenty-buttons-and-links'
import { Icon } from '#app/components/ui/icon'
import { SheetClose } from '#app/components/ui/sheet'
import { cn } from '#app/utils/misc'
import { type NavigationCategoriesStoryblok } from '#types/component-types-sb'
import PlentyIcon from '../../footer-components/plenty-icon'
import { SlideClose } from '../slides'

interface TopNavigationDiscountProps {
	navigationData: NavigationCategoriesStoryblok[]
	selectedCategory: string | null
	onCategoryClick: (title: string) => void
}

const TopNavigationDiscount = ({
	navigationData,
	selectedCategory,
	onCategoryClick,
}: TopNavigationDiscountProps) => {
	const { t } = useTranslation('navbar')

	return (
		<div className="flex flex-col gap-2 px-4">
			<div className="flex flex-row justify-between  pb-1 pt-4">
				<PlentyIcon />
				<div className="flex flex-row space-x-4">
					{navigationData.map(item => {
						const isActive = selectedCategory === item.title
						return (
							<div
								className={cn(
									'relative flex w-full cursor-pointer items-center justify-center',
								)}
								onClick={() => onCategoryClick(item.title!)}
								key={item._uid}
							>
								<PlentyButtonsAndLinks
									as="nav-md"
									className={cn(' text-black-90', isActive && 'font-bold')}
								>
									{item.title}
								</PlentyButtonsAndLinks>
								{isActive && (
									<motion.div
										className="absolute bottom-0 h-[2px] w-6 bg-black-90"
										layoutId="underlineDiscount"
									/>
								)}
							</div>
						)
					})}
				</div>
				<SheetClose className="flex items-center justify-end">
					<Icon name="close" size="lg" />
				</SheetClose>
			</div>
			<SlideClose>
				<div className="flex flex-row items-center gap-2">
					<Icon name="chevron-left-arrow" className="text-black" size="lg" />
					<PlentyButtonsAndLinks as="nav-sm" className="">
						{selectedCategory}
					</PlentyButtonsAndLinks>
					<Icon name="slash" size="lg" />

					<PlentyButtonsAndLinks as="nav-sm" className="font-bold">
						{t('discount')}
					</PlentyButtonsAndLinks>
				</div>
			</SlideClose>
		</div>
	)
}

export default TopNavigationDiscount
