import DiscountInformation from './discount-information'
import DiscountLocationAndAudience from './discount-location-and-audience'
import DiscountImageAndImageText from './image-and-image-text'

const Discount = ({ discount }: { discount: any }) => {
	// Dynamically set the width based on `fullWidth`
	const widthClass = discount.fullWidth ? 'w-full' : 'w-1/2'

	return (
		<div className={`flex ${widthClass} flex-col`}>
			<div className="flex flex-col gap-2 rounded-[8px] border border-beige-70 p-2">
				<DiscountImageAndImageText discount={discount} />
				<DiscountInformation discount={discount} />
				<DiscountLocationAndAudience discount={discount} />
			</div>
		</div>
	)
}

export default Discount
