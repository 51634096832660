import { useLocation } from '@remix-run/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from '#app/components/ui/icon'
import { Sheet, SheetContent, SheetTrigger } from '#app/components/ui/sheet'
import MobileSearchDrawer from '#app/features/clerk-search/search-drawer'
import { usePlentyMediaQuery } from '#app/hooks/use-plenty-media-query'
import { type NavigationCategoriesStoryblok } from '#types/component-types-sb'
import MainContent from './main-content'
import MobileNavFooter from './mobile-nav-footer'
import MobileNavLayout from './mobile-nav-layout'
import ScrollableContent from './scrollable-content'
import MobileSearch from './search'
import { Slide, SlideItems, SlideMainContent } from './slides'

interface ResponsiveNavbarProps {
	navItems: {
		link: string
		name: string
	}[]
	navigationData: NavigationCategoriesStoryblok[]
}

const MobileNavBar = ({ navItems, navigationData }: ResponsiveNavbarProps) => {
	const [open, setOpen] = useState(false)
	const [isDrawerOpen, setDrawerOpen] = useState(false)
	const [selectedCategory, setSelectedCategory] = useState<string | null>(null)
	const [lastVisitedLanding] = useState('/')

	const isDesktop = usePlentyMediaQuery('(min-width: 820px)')
	const { t, i18n } = useTranslation('categories')
	const location = useLocation()
	const [isCategoryManuallySelected, setIsCategoryManuallySelected] =
		useState(false)

	const handleCategoryClick = (title: string) => {
		setSelectedCategory(title)
		setIsCategoryManuallySelected(true) // Set flag when category is manually selected
	}
	const filteredNavigationData = navigationData.filter(
		item => !item.hideInMobile,
	)

	// close the sheet when the route changes
	useEffect(() => {
		setOpen(false)
	}, [location.pathname])

	useEffect(() => {
		if (!isCategoryManuallySelected) {
			const matchedCategory = filteredNavigationData.find(item =>
				location.pathname.toLowerCase().includes(item.title!.toLowerCase()),
			)
			setSelectedCategory(matchedCategory?.title || t('women'))
		}
	}, [location.pathname, filteredNavigationData, isCategoryManuallySelected, t])

	useEffect(() => {
		// Close the drawer when the language changes
		const handleLanguageChange = () => {
			setOpen(false)
		}

		i18n.on('languageChanged', handleLanguageChange)

		return () => {
			i18n.off('languageChanged', handleLanguageChange)
		}
	}, [i18n])

	useEffect(() => {
		if (open) {
			document.body.classList.add('no-scroll')
		} else {
			document.body.classList.remove('no-scroll')
		}

		return () => {
			document.body.classList.remove('no-scroll')
		}
	}, [open])

	useEffect(() => {
		if (isDesktop) {
			setOpen(false)
		}
	}, [isDesktop])

	return (
		<Sheet open={open} onOpenChange={setOpen}>
			<SheetTrigger className="flex items-center justify-center">
				<Icon className="text-body-md block md:hidden" name="menu" size="lg" />
			</SheetTrigger>

			<SheetContent
				className="space-y sheet-content flex w-full max-w-full flex-col border-0 p-0 sm:max-w-full"
				side="right"
				hiddenClose
			>
				<Slide key={`slider-${open ? 'open' : 'close'}`}>
					<SlideItems>
						<SlideMainContent slideKey="main">
							<ScrollableContent className="h-full space-y-3">
								<MobileNavLayout
									selectedCategory={selectedCategory}
									onCategoryClick={handleCategoryClick}
									navigationData={filteredNavigationData}
								/>

								<MainContent
									selectedCategory={selectedCategory}
									navigationData={filteredNavigationData}
								/>
								<MobileNavFooter />
							</ScrollableContent>
						</SlideMainContent>
					</SlideItems>
					<MobileSearch
						selectedCategory={selectedCategory}
						navigationData={filteredNavigationData}
						onCategoryClick={handleCategoryClick}
					/>
				</Slide>
			</SheetContent>
			<MobileSearchDrawer
				setOpen={setDrawerOpen}
				open={isDrawerOpen}
				lastVisitedLanding={lastVisitedLanding}
			/>
		</Sheet>
	)
}

export default MobileNavBar
