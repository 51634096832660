import { useTranslation } from 'react-i18next'
import PlentyBody from '#app/components/typography/plenty-body'
import { Icon } from '#app/components/ui/icon'

const MobileNavStaticInfo = () => {
	const { t } = useTranslation('navbar')

	return (
		<div>
			<div className="flex flex-row space-x-2">
				<div className="flex w-full items-center gap-2 rounded border border-beige-50 p-1">
					<Icon name="pickup" size="lg" />
					<div className="flex flex-col">
						<PlentyBody size="sm">{t('always_free')}</PlentyBody>
						<PlentyBody size="sm">{t('click_and_collect')}</PlentyBody>
					</div>
				</div>
				<div className="flex w-full items-center gap-2 rounded border border-beige-50 p-1">
					<Icon name="delivery-truck" className="text-black" size="lg" />
					<div className="flex flex-col">
						<PlentyBody size="sm">{t('always_bought')}</PlentyBody>
						<PlentyBody size="sm">{t('local_store')}</PlentyBody>
					</div>
				</div>
			</div>
		</div>
	)
}

export default MobileNavStaticInfo
