import { useLoaderData } from '@remix-run/react'
import { useTranslation } from 'react-i18next'
import DiscountBlok from '#app/components/storyblok/discount'
import PlentyButtonsAndLinks from '#app/components/typography/plenty-buttons-and-links'
import { Icon } from '#app/components/ui/icon'
import { type loader } from '#app/routes/_public+/_layout'
import { cn } from '#app/utils/misc'
import { type NavigationCategoriesStoryblok } from '#types/component-types-sb'
import NoCurrentDiscountsLive from './discounts/no-current-discounts-live'
import TopNavigationDiscount from './discounts/top-navigation-discount'
import MobileNavFooter from './mobile-nav-footer'
import ScrollableContent from './scrollable-content'
import { SlideContent, SlideTrigger } from './slides'

// Define the necessary props for DiscountTrigger to pass down to TopNavigation
interface DiscountTriggerProps {
	selectedCategory: string | null
	onCategoryClick: (title: string) => void
	navigationData: NavigationCategoriesStoryblok[]
}

const DiscountTrigger = ({
	selectedCategory,
	onCategoryClick,
	navigationData,
}: DiscountTriggerProps) => {
	const { t } = useTranslation('navbar')
	const { discounts } = useLoaderData<typeof loader>()

	const hasDiscounts = discounts && discounts.length > 0
	return (
		<div>
			<SlideTrigger
				slideKey="discounts"
				className="bg-ampersandDiscountsMobile flex flex-row justify-between rounded bg-beige-50 bg-no-repeat p-3 "
			>
				<PlentyButtonsAndLinks as="nav-md" className={cn(' text-black-90')}>
					{t('active_discounts')}
				</PlentyButtonsAndLinks>
				<Icon name="right-arrow" size="lg" />
			</SlideTrigger>
			<SlideContent className="flex flex-col gap-3" slideKey="discounts">
				<ScrollableContent className="h-auto  space-y-3">
					{/* Use TopNavigation inside the SlideContent */}
					<TopNavigationDiscount
						selectedCategory={selectedCategory}
						onCategoryClick={onCategoryClick}
						navigationData={navigationData}
					/>
					{hasDiscounts ? (
						<DiscountBlok />
					) : (
						<NoCurrentDiscountsLive selectedCategory={selectedCategory} />
					)}
					<MobileNavFooter />´{' '}
				</ScrollableContent>
			</SlideContent>
		</div>
	)
}

export default DiscountTrigger
