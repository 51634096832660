import {
	differenceInDays,
	differenceInHours,
	differenceInMinutes,
} from 'date-fns'
import { useEffect, useState } from 'react'
import PlentyBody from '#app/components/typography/plenty-body'
import { Icon } from '#app/components/ui/icon'
const Countdown = ({ discount }: any) => {
	const [timeRemaining, setTimeRemaining] = useState({
		days: 0,
		hours: 0,
		minutes: 0,
	})
	const endDate = new Date(discount.endTimeForDiscount)

	const calculateTimeLeft = () => {
		const now = new Date()
		const days = differenceInDays(endDate, now)
		const hours = differenceInHours(endDate, now) % 24
		const minutes = differenceInMinutes(endDate, now) % 60

		return { days, hours, minutes }
	}

	useEffect(() => {
		setTimeRemaining(calculateTimeLeft())
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	return (
		<div className="absolute -bottom-3 left-2 flex w-fit gap-1 rounded-[12px] border bg-white px-1 py-[2px]">
			<Icon className="text-black" size="sm" name="clock" />
			<PlentyBody>
				{timeRemaining.days}D {timeRemaining.hours}H {timeRemaining.minutes}M
			</PlentyBody>
		</div>
	)
}

export default Countdown
