import { Icon } from '#app/components/ui/icon'
import { cn } from '#app/utils/misc'

interface PlentyIconProps {
	className?: string
}
const PlentyIcon = ({ className }: PlentyIconProps) => {
	return (
		<div>
			<Icon className={cn('h-[22px] w-[77px]', className)} name="plenty" />
		</div>
	)
}
export default PlentyIcon
