import { Link } from '@remix-run/react'
import { StoryblokComponent, renderRichText } from '@storyblok/react'
import SeparatorLine from '#app/components/common/separator-line'
import renderEmojis from '#app/components/storyblok/emoji-renderer'
import PlentyBody from '#app/components/typography/plenty-body'
import PlentyButtonsAndLinks from '#app/components/typography/plenty-buttons-and-links'
import { Icon } from '#app/components/ui/icon'
import {
	type CategoryLinkStoryblok,
	type NavigationCategoriesStoryblok,
} from '#types/component-types-sb'
import CategoriesContent from './categories/categories-content'
import SpecialCategories from './categories/special-categories'
import ScrollableContent from './scrollable-content'
import { SlideContent, SlideTrigger } from './slides'

interface MainContentProps {
	selectedCategory: string | null
	navigationData: NavigationCategoriesStoryblok[]
}

const MainContent = ({
	selectedCategory,
	navigationData,
}: MainContentProps) => {
	// move this filter to the server/loader @todo
	const matchedCategory = navigationData.find(
		item => item.title === selectedCategory,
	)

	if (!matchedCategory) {
		return (
			<PlentyBody size="sm">No content available for this category</PlentyBody>
		)
	}
	const mobileContent = matchedCategory.mobileContent || []

	const specialCategories =
		matchedCategory.mobileCategories?.filter(
			category =>
				category.component === 'parentCategory' &&
				category.isLarge &&
				category.mobileImage,
		) || []

	const restOfCategories =
		matchedCategory.mobileCategories?.filter(
			category => !category.isLarge || !category.mobileImage,
		) || []

	return (
		<>
			<div className="h-fit w-full">
				<nav className="grid w-full gap-6 px-4 ">
					{restOfCategories.length > 0 &&
						restOfCategories.map(category => {
							if (category.component === 'categoryLink') {
								return <CategoryLink category={category} key={category._uid} />
							}
							return (
								<div key={category._uid}>
									<SlideTrigger slideKey={category._uid}>
										<div className="flex cursor-pointer items-center justify-between gap-2 text-muted-foreground hover:text-foreground">
											<PlentyButtonsAndLinks as="nav-md">
												{category.title}
											</PlentyButtonsAndLinks>
											{category?.categories &&
												category.categories.length > 0 && (
													<Icon name="chevron-right-arrow" size="lg" />
												)}
										</div>
									</SlideTrigger>
									<SlideContent slideKey={category._uid}>
										<ScrollableContent className="h-full space-y-3">
											<CategoriesContent
												category={category}
												parentCategory={matchedCategory.title || ''}
											/>
										</ScrollableContent>
									</SlideContent>
								</div>
							)
						})}
				</nav>
				{specialCategories.length > 0 && (
					<div className="mt-2 grid grid-cols-2 gap-2 px-4 ">
						{specialCategories.map(category => {
							return (
								category.component === 'parentCategory' && (
									<SpecialCategories
										category={category}
										parentCategory={matchedCategory.title || ''}
										key={`special-${category._uid}`}
									/>
								)
							)
						})}
					</div>
				)}
			</div>
			<SeparatorLine />
			{mobileContent.map(item => (
				<StoryblokComponent blok={item} key={item._uid} />
			))}
		</>
	)
}

const CategoryLink = ({ category }: { category: CategoryLinkStoryblok }) => {
	const richTextHTML = renderRichText(category.title)
	return (
		<Link
			className="flex w-full cursor-pointer items-center justify-between gap-2 text-[14px] leading-[22px]"
			to={category.link}
		>
			{renderEmojis(richTextHTML, {})}
		</Link>
	)
}

export default MainContent
