import BlackBackgroundColorForImages from '#app/components/black-background-color-for-images'
import PlentyHeading from '#app/components/typography/plenty-heading'
import Countdown from './discount-countdown'

const DiscountImageAndImageText = ({ discount }: any) => {
	return (
		<div className="relative w-full">
			<img
				className="w-full rounded"
				src={discount.image?.filename}
				alt={discount.image?.alt || ''}
			/>
			<BlackBackgroundColorForImages className="rounded" />
			<div className="absolute inset-0 flex items-center justify-center px-4">
				<PlentyHeading as="h5" className={`text-${discount.imageTextColor}`}>
					{discount.imageText}
				</PlentyHeading>
			</div>
			{/* Time remaining in the bottom-left corner */}
			<Countdown discount={discount} />
		</div>
	)
}

export default DiscountImageAndImageText
