// context and provider
import { motion, useAnimation } from 'framer-motion'
import {
	type Dispatch,
	createContext,
	useReducer,
	useContext,
	useEffect,
} from 'react'
import { createPortal } from 'react-dom'
import { cn } from '#app/utils/misc'

// types and context
type SlideState = {
	currentSlide: 'main' | string
}

const initialSlideState: SlideState = {
	currentSlide: 'main',
}

export const SlidesContext = createContext<SlideState>(initialSlideState)

export const SlidesDispatchContext = createContext<Dispatch<any> | null>(null)

enum Actions {
	SHOW_MAIN = 'showMain',
	SHOW_REST = 'showRest',
}

type SlideAction = { type: 'showMain' } | { type: 'showRest'; slideKey: string }

function slideReducer(state: SlideState, action: SlideAction): SlideState {
	switch (action.type) {
		case Actions.SHOW_MAIN:
			return { currentSlide: 'main' }
		case Actions.SHOW_REST:
			return { currentSlide: action.slideKey }
		default:
			throw new Error(`Unknown action: ${action.type}`)
	}
}

// animations

const mainAnimations = {
	mainSelected: {
		x: '0%', // Keep the initial page centered
		opacity: 1, // Ensure opacity stays at 1
		transition: {
			type: 'spring', // Smooth spring transition
			stiffness: 300,
			damping: 30,
			duration: 0.6,
			ease: 'easeInOut',
		},
	},
	restSlidesSelected: {
		x: '-100%', // Slide out to the left
		opacity: 0, // Keep opacity constant, no fading
		transition: {
			type: 'spring',
			stiffness: 300,
			damping: 30,
			duration: 0.6,
			ease: 'easeInOut',
		},
	},
}

const restAnimations = {
	mainSelected: {
		x: '100%', // Start off to the right, outside the viewport
		opacity: 0, // Keep opacity constant, no fading
		transition: {
			type: 'spring',
			stiffness: 300,
			damping: 30,
			duration: 0.6,
			ease: 'easeInOut',
		},
	},
	restSlidesSelected: {
		x: '0%', // Bring the discounts page to the center
		opacity: 1, // Ensure opacity stays at 1
		transition: {
			type: 'spring',
			stiffness: 300,
			damping: 30,
			duration: 0.6,
			ease: 'easeInOut',
		},
	},
}

// slides components

interface SlideProps extends React.HTMLAttributes<HTMLDivElement> {}

const Slide = ({ children }: SlideProps) => {
	const [slides, dispatch] = useReducer(slideReducer, initialSlideState)
	const controls = useAnimation() // Initialize the controls for animation

	useEffect(() => {
		return () => {
			dispatch({ type: 'showMain' })
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	return (
		<SlidesContext.Provider value={slides}>
			<SlidesDispatchContext.Provider value={dispatch}>
				<motion.div animate={controls}>{children}</motion.div>
			</SlidesDispatchContext.Provider>
		</SlidesContext.Provider>
	)
}

interface SlideTriggerProps extends React.HTMLAttributes<HTMLDivElement> {
	slideKey: string
}

const SlideTrigger = ({
	slideKey,
	children,
	onClick,
	...rest
}: SlideTriggerProps) => {
	const dispatch = useContext(SlidesDispatchContext)
	const handleOnTrigger = (e: any) => {
		onClick && onClick(e)
		dispatch && dispatch({ type: 'showRest', slideKey })
	}
	return (
		<div onClick={handleOnTrigger} {...rest}>
			{children}
		</div>
	)
}

interface SlideCloseProps extends React.HTMLAttributes<HTMLDivElement> {}

const SlideClose = ({ children }: SlideCloseProps) => {
	const dispatch = useContext(SlidesDispatchContext)
	const handleOnClose = () => {
		dispatch && dispatch({ type: 'showMain' })
	}
	return <div onClick={handleOnClose}>{children}</div>
}

const SlideMainContent = ({ children }: SlideContentProps) => {
	const { currentSlide } = useContext(SlidesContext)
	const mainAnimate =
		currentSlide === 'main' ? 'mainSelected' : 'restSlidesSelected'
	return (
		<motion.div
			variants={mainAnimations}
			animate={mainAnimate}
			className={cn(
				'space-y absolute top-0 flex h-full w-full max-w-full flex-col border-0 p-0 sm:max-w-full',
			)}
			style={{ x: 0 }}
		>
			{children}
		</motion.div>
	)
}

interface SlideContentProps extends React.HTMLAttributes<HTMLDivElement> {
	slideKey: 'main' | string
}

interface SlideItemsProps extends React.HTMLAttributes<HTMLDivElement> {}
const SlideItems = ({ children }: SlideItemsProps) => {
	return (
		<>
			{children}
			<div id="content-portal" />
		</>
	)
}

const SlideContent = ({ slideKey, children }: SlideContentProps) => {
	const { currentSlide } = useContext(SlidesContext)
	const mainAnimate =
		currentSlide === 'main' ? 'mainSelected' : 'restSlidesSelected'
	const restAnimate =
		currentSlide !== 'main' ? 'restSlidesSelected' : 'mainSelected'

	const isMain = slideKey === 'main'
	const content = (
		<motion.div
			variants={slideKey === 'main' ? mainAnimations : restAnimations}
			animate={slideKey === 'main' ? mainAnimate : restAnimate}
			className={cn(
				'space-y absolute top-0 flex h-full w-full max-w-full flex-col border-0 p-0 sm:max-w-full',
				{
					visible: !isMain && slideKey === currentSlide,
					hidden: !isMain && slideKey !== currentSlide,
				},
			)}
			style={{ x: 0 }}
		>
			{children}
		</motion.div>
	)

	const portalElement = document.getElementById('content-portal')
	return portalElement ? createPortal(content, portalElement) : null
}

export {
	Slide,
	SlideTrigger,
	SlideContent,
	SlideClose,
	SlideItems,
	SlideMainContent,
}
