import { useLoaderData } from '@remix-run/react'
import { useTranslation } from 'react-i18next'
import { type loader } from '#app/routes/_public+/_layout'
import Discount from '../navigation/mobile-navbar/discounts'
import PlentyButtonsAndLinks from '../typography/plenty-buttons-and-links'

const DiscountBlok = () => {
	const { discounts } = useLoaderData<typeof loader>()
	const { t } = useTranslation('navbar')

	// Get the current date
	const now = new Date()

	// Filter discounts based on storeDiscount flag and valid time span
	const personalDiscounts = discounts.filter(discount => {
		const { endTimeForDiscount, storeDiscount } = discount.content

		const endTime = new Date(endTimeForDiscount)
		// Ensure the discount is within the valid time span and is a personal discount
		return !storeDiscount && endTime >= now // Check that endTime is not earlier than today
	})

	const storeDiscounts = discounts.filter(discount => {
		const { endTimeForDiscount, storeDiscount } = discount.content
		const endTime = new Date(endTimeForDiscount)
		// Ensure the discount is within the valid time span and is a store discount
		return storeDiscount && endTime >= now // Check that endTime is not earlier than today
	})
	return (
		<div className="flex h-auto flex-col gap-4 px-4 py-3">
			{/* Personal Offers Section */}
			{personalDiscounts.length > 0 && (
				<div className="flex flex-col gap-2">
					<PlentyButtonsAndLinks as="nav-sm">
						{t('personal_offer')}
					</PlentyButtonsAndLinks>
					<div className="flex flex-wrap gap-4">
						{personalDiscounts.map((discount, index) => (
							<Discount key={index} discount={discount.content} />
						))}
					</div>
				</div>
			)}

			{/* Store Discounts Section */}
			{storeDiscounts.length > 0 && (
				<div className="flex flex-col gap-2">
					<PlentyButtonsAndLinks as="nav-sm">
						{t('store_discounts')}
					</PlentyButtonsAndLinks>
					<div className="flex gap-4">
						{storeDiscounts.map((discount, index) => (
							<Discount key={index} discount={discount.content} />
						))}
					</div>
				</div>
			)}
		</div>
	)
}

export default DiscountBlok
