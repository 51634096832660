import PlentyBody from '#app/components/typography/plenty-body'

const DiscountInformation = ({ discount }: any) => {
	return (
		<div className="flex flex-col pt-2">
			<PlentyBody className="font-bold">{discount.discountText}</PlentyBody>
			<PlentyBody>{discount.discountAmountText}</PlentyBody>
		</div>
	)
}

export default DiscountInformation
