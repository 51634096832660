import PlentyButtonsAndLinks from '#app/components/typography/plenty-buttons-and-links'
import { Icon } from '#app/components/ui/icon'
import { SheetClose } from '#app/components/ui/sheet'
import PlentyIcon from '../../footer-components/plenty-icon'
import { SlideClose } from '../slides'

interface CategoryNavigationProps {
	selectedCategory: string
	parentCategory: string
}
const CategoryNavigation = ({
	selectedCategory,
	parentCategory,
}: CategoryNavigationProps) => {
	return (
		<div className="space-y-3 px-4">
			<div className="flex flex-row justify-between  pb-1 pt-4">
				<PlentyIcon />

				<SheetClose className="flex items-center justify-end">
					<Icon name="close" size="lg" />
				</SheetClose>
			</div>
			<div className="flex flex-row items-center justify-start space-x-2">
				<SlideClose>
					<Icon
						name="chevron-left-arrow"
						size="md"
						className="cursor-pointer"
					/>
				</SlideClose>
				<SlideClose>
					<PlentyButtonsAndLinks
						as="nav-md"
						className="cursor-pointer text-black-90"
					>
						{parentCategory}
					</PlentyButtonsAndLinks>
				</SlideClose>
				<Icon name="slash" size="md" />
				<PlentyButtonsAndLinks as="nav-md" className=" font-bold text-black-90">
					{selectedCategory}
				</PlentyButtonsAndLinks>
			</div>
		</div>
	)
}

export default CategoryNavigation
